export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_SUBSCRIPTION_DETAILS_START =
  "UPDATE_USER_SUBSCRIPTION_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const EDIT_CHANGE_PASSWORD = "EDIT_CHANGE_PASSWORD";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const FETCH_PAYMENTS_START = "FETCH_PAYMENTS_START";
export const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS";
export const FETCH_PAYMENTS_FAILURE = "FETCH_PAYMENTS_FAILURE";

export const GET_LOGIN_DETAILS = "GET_LOGIN_DETAILS";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const GET_REGISTER_DETAILS = "GET_REGISTER_DETAILS";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const GET_FORGOT_PASSWORD_DETAILS = "GET_FORGOT_PASSWORD_DETAILS";

export const FETCH_CONTENT_CREATOR_DASHBOARD_START =
  "FETCH_CONTENT_CREATOR_DASHBOARD_START";
export const FETCH_CONTENT_CREATOR_DASHBOARD_SUCCESS =
  "FETCH_CONTENT_CREATOR_DASHBOARD_SUCCESS";
export const FETCH_CONTENT_CREATOR_DASHBOARD_FAILURE =
  "FETCH_CONTENT_CREATOR_DASHBOARD_FAILURE";

// Subscription constants

export const FETCH_SUBSCRIPTION_START = "FETCH_SUBSCRIPTION_START";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_FAILURE = "FETCH_SUBSCRIPTION_FAILURE";

export const FETCH_MY_SUBSCRIPTION_START = "FETCH_MY_SUBSCRIPTION_START";
export const FETCH_MY_SUBSCRIPTION_SUCCESS = "FETCH_MY_SUBSCRIPTION_SUCCESS";
export const FETCH_MY_SUBSCRIPTION_FAILURE = "FETCH_MY_SUBSCRIPTION_FAILURE";

export const FETCH_SINGLE_SUBSCRIPTION_START =
  "FETCH_SINGLE_SUBSCRIPTION_START";
export const FETCH_SINGLE_SUBSCRIPTION_SUCCESS =
  "FETCH_SINGLE_SUBSCRIPTION_SUCCESS";
export const FETCH_SINGLE_SUBSCRIPTION_FAILURE =
  "FETCH_SINGLE_SUBSCRIPTION_FAILURE";

export const SUBSCRIPTION_PAYMENT_STRIPE_START =
  "SUBSCRIPTION_PAYMENT_STRIPE_START";
export const SUBSCRIPTION_PAYMENT_STRIPE_SUCCESS =
  "SUBSCRIPTION_PAYMENT_STRIPE_SUCCESS";
export const SUBSCRIPTION_PAYMENT_STRIPE_FAILURE =
  "SUBSCRIPTION_PAYMENT_STRIPE_FAILURE";

export const SUBSCRIPTION_PAYMENT_WALLET_START =
  "SUBSCRIPTION_PAYMENT_WALLET_START";
export const SUBSCRIPTION_PAYMENT_WALLET_SUCCESS =
  "SUBSCRIPTION_PAYMENT_WALLET_SUCCESS";
export const SUBSCRIPTION_PAYMENT_WALLET_FAILURE =
  "SUBSCRIPTION_PAYMENT_WALLET_FAILURE";

export const SUBSCRIPTION_AUTO_RENEWAL_START =
  "SUBSCRIPTION_AUTO_RENEWAL_START";
export const SUBSCRIPTION_AUTO_RENEWAL_SUCCESS =
  "SUBSCRIPTION_AUTO_RENEWAL_SUCCESS";
export const SUBSCRIPTION_AUTO_RENEWAL_FAILURE =
  "SUBSCRIPTION_AUTO_RENEWAL_FAILURE";

// Cards constants

export const FETCH_CARD_DETAILS_START = "FETCH_CARD_DETAILS_START";
export const FETCH_CARD_DETAILS_SUCCESS = "FETCH_CARD_DETAILS_SUCCESS";
export const FETCH_CARD_DETAILS_FAILURE = "FETCH_CARD_DETAILS_FAILURE";

export const DELETE_CARD_START = "DELETE_CARD_START";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const SELECT_DEFAULT_CARD_START = "SELECT_DEFAULT_CARD_START";
export const SELECT_DEFAULT_CARD_SUCCESS = "SELECT_DEFAULT_CARD_SUCCESS";
export const SELECT_DEFAULT_CARD_FAILURE = "SELECT_DEFAULT_CARD_FAILURE";

// Bank account

export const ADD_BANK_ACCOUNT_START = "ADD_BANK_ACCOUNT_START";
export const ADD_BANK_ACCOUNT_CONTENT_CREATOR_FLOW_START =
  "ADD_BANK_ACCOUNT_CONTENT_CREATOR_FLOW_START";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const ADD_BANK_ACCOUNT_FAILURE = "ADD_BANK_ACCOUNT_FAILURE";

export const GET_BANK_ACCOUNT_START = "GET_BANK_ACCOUNT_START";
export const GET_BANK_ACCOUNT_SUCCESS = "GET_BANK_ACCOUNT_SUCCESS";
export const GET_BANK_ACCOUNT_FAILURE = "GET_BANK_ACCOUNT_FAILURE";

export const DELETE_BANK_ACCOUNT_START = "DELETE_BANK_ACCOUNT_START";
export const DELETE_BANK_ACCOUNT_CONTENT_CREATOR_FLOW_START =
  "DELETE_BANK_ACCOUNT_CONTENT_CREATOR_FLOW_START";
export const DELETE_BANK_ACCOUNT_SUCCESS = "DELETE_BANK_ACCOUNT_SUCCESS";
export const DELETE_BANK_ACCOUNT_FAILURE = "DELETE_BANK_ACCOUNT_FAILURE";

export const ADD_BANK_ACCOUNT_DATA = "ADD_BANK_ACCOUNT_DATA";

export const FETCH_SINGLE_BANK_ACCOUNT_START =
  "FETCH_SINGLE_BANK_ACCOUNT_START";
export const FETCH_SINGLE_BANK_ACCOUNT_SUCCESS =
  "FETCH_SINGLE_BANK_ACCOUNT_SUCCESS";
export const FETCH_SINGLE_BANK_ACCOUNT_FAILURE =
  "FETCH_SINGLE_BANK_ACCOUNT_FAILURE";

export const MAKE_DEFAULT_BANK_ACCOUNT_START =
  "MAKE_DEFAULT_BANK_ACCOUNT_START";
export const MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS =
  "MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS";
export const MAKE_DEFAULT_BANK_ACCOUNT_FAILURE =
  "MAKE_DEFAULT_BANK_ACCOUNT_FAILURE";

export const GET_KYC_DOCUMENT_START = "GET_KYC_DOCUMENT_START";
export const GET_KYC_DOCUMENT_SUCCESS = "GET_KYC_DOCUMENT_SUCCESS";
export const GET_KYC_DOCUMENT_FAILURE = "GET_KYC_DOCUMENT_FAILURE";

export const ADD_KYC_DOCUMENT_START = "ADD_KYC_DOCUMENT_START";
export const ADD_KYC_DOCUMENT_SUCCESS = "ADD_KYC_DOCUMENT_SUCCESS";
export const ADD_KYC_DOCUMENT_FAILURE = "ADD_KYC_DOCUMENT_FAILURE";

// Wallet constants

export const FETCH_WALLET_DETAILS_START = "FETCH_WALLET_DETAILS_START";
export const FETCH_WALLET_DETAILS_SUCCESS = "FETCH_WALLET_DETAILS_SUCCESS";
export const FETCH_WALLET_DETAILS_FAILURE = "FETCH_WALLET_DETAILS_FAILURE";

export const ADD_MONEY_VIA_CARD_START = "ADD_MONEY_VIA_CARD_START";
export const ADD_MONEY_VIA_CARD_SUCCESS = "ADD_MONEY_VIA_CARD_SUCCESS";
export const ADD_MONEY_VIA_CARD_FAILURE = "ADD_MONEY_VIA_CARD_FAILURE";

export const ADD_MONEY_VIA_BANK_START = "ADD_MONEY_VIA_BANK_START";
export const ADD_MONEY_VIA_BANK_SUCCESS = "ADD_MONEY_VIA_BANK_SUCCESS";
export const ADD_MONEY_VIA_BANK_FAILURE = "ADD_MONEY_VIA_BANK_FAILURE";

export const ADD_MONEY_VIA_PAYPAL_START = "ADD_MONEY_VIA_PAYPAL_START";
export const ADD_MONEY_VIA_PAYPAL_SUCCESS = "ADD_MONEY_VIA_PAYPAL_SUCCESS";
export const ADD_MONEY_VIA_PAYPAL_FAILURE = "ADD_MONEY_VIA_PAYPAL_FAILURE";

// Transaction constants.

export const FETCH_ALL_TRANSACTION_START = "FETCH_ALL_TRANSACTION_START";
export const FETCH_ALL_TRANSACTION_SUCCESS = "FETCH_ALL_TRANSACTION_SUCCESS";
export const FETCH_ALL_TRANSACTION_FAILURE = "FETCH_ALL_TRANSACTION_FAILURE";

export const FETCH_SENT_PAYMENT_TRANSACTION_START =
  "FETCH_SENT_PAYMENT_TRANSACTION_START";
export const FETCH_SENT_PAYMENT_TRANSACTION_SUCCESS =
  "FETCH_SENT_PAYMENT_TRANSACTION_SUCCESS";
export const FETCH_SENT_PAYMENT_TRANSACTION_FAILURE =
  "FETCH_SENT_PAYMENT_TRANSACTION_FAILURE";

export const FETCH_RECEIVED_PAYMENT_TRANSACTION_START =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_START";
export const FETCH_RECEIVED_PAYMENT_TRANSACTION_SUCCESS =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_SUCCESS";
export const FETCH_RECEIVED_PAYMENT_TRANSACTION_FAILURE =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_FAILURE";

export const FETCH_DEPOSIT_TRANSACTION_START =
  "FETCH_DEPOSIT_TRANSACTION_START";
export const FETCH_DEPOSIT_TRANSACTION_SUCCESS =
  "FETCH_DEPOSIT_TRANSACTION_SUCCESS";
export const FETCH_DEPOSIT_TRANSACTION_FAILURE =
  "FETCH_DEPOSIT_TRANSACTION_FAILURE";

// Invoice constants.

export const SAVE_BUSINESS_DETAILS_START = "SAVE_BUSINESS_DETAILS_START";
export const SAVE_BUSINESS_DETAILS_SUCCESS = "SAVE_BUSINESS_DETAILS_SUCCESS";
export const SAVE_BUSINESS_DETAILS_FAILURE = "SAVE_BUSINESS_DETAILS_FAILURE";

export const FETCH_BUSINESS_DETAILS_START = "FETCH_BUSINESS_DETAILS_START";
export const FETCH_BUSINESS_DETAILS_SUCCESS = "FETCH_BUSINESS_DETAILS_SUCCESS";
export const FETCH_BUSINESS_DETAILS_FAILURE = "FETCH_BUSINESS_DETAILS_FAILURE";

// Withdraw constants

export const SEND_WITHDRAW_REQUEST_START = "SEND_WITHDRAW_REQUEST_START";
export const SEND_WITHDRAW_REQUEST_SUCCESS = "SEND_WITHDRAW_REQUEST_SUCCESS";
export const SEND_WITHDRAW_REQUEST_FAILURE = "SEND_WITHDRAW_REQUEST_FAILURE";

export const FETCH_WITHDRAWALS_START = "FETCH_WITHDRAWALS_START";
export const FETCH_WITHDRAWALS_SUCCESS = "FETCH_WITHDRAWALS_SUCCESS";
export const FETCH_WITHDRAWALS_FAILURE = "FETCH_WITHDRAWALS_FAILURE";

export const CANCEL_WITHDRAW_REQUEST_START = "CANCEL_WITHDRAW_REQUEST_START";
export const CANCEL_WITHDRAW_REQUEST_SUCCESS =
  "CANCEL_WITHDRAW_REQUEST_SUCCESS";
export const CANCEL_WITHDRAW_REQUEST_FAILURE =
  "CANCEL_WITHDRAW_REQUEST_FAILURE";

export const SEARCH_WITHDRAWALS_START = "SEARCH_WITHDRAWALS_START";
export const SEARCH_WITHDRAWALS_SUCCESS = "SEARCH_WITHDRAWALS_SUCCESS";
export const SEARCH_WITHDRAWALS_FAILURE = "SEARCH_WITHDRAWALS_FAILURE";

export const FETCH_SINGLE_WITHDRAWALS_START = "FETCH_SINGLE_WITHDRAWALS_START";
export const FETCH_SINGLE_WITHDRAWALS_SUCCESS =
  "FETCH_SINGLE_WITHDRAWALS_SUCCESS";
export const FETCH_SINGLE_WITHDRAWALS_FAILURE =
  "FETCH_SINGLE_WITHDRAWALS_FAILURE";

// Dispute constants

export const SEND_DISPUTE_REQUEST_START = "SEND_DISPUTE_REQUEST_START";
export const SEND_DISPUTE_REQUEST_SUCCESS = "SEND_DISPUTE_REQUEST_SUCCESS";
export const SEND_DISPUTE_REQUEST_FAILURE = "SEND_DISPUTE_REQUEST_FAILURE";

export const FETCH_DISPUTES_START = "FETCH_DISPUTES_START";
export const FETCH_DISPUTES_SUCCESS = "FETCH_DISPUTES_SUCCESS";
export const FETCH_DISPUTES_FAILURE = "FETCH_DISPUTES_FAILURE";

export const FETCH_OPENED_CASE_START = "FETCH_OPENED_CASE_START";
export const FETCH_OPENED_CASE_SUCCESS = "FETCH_OPENED_CASE_SUCCESS";
export const FETCH_OPENED_CASE_FAILURE = "FETCH_OPENED_CASE_FAILURE";

export const FETCH_CLOSED_CASE_START = "FETCH_CLOSED_CASE_START";
export const FETCH_CLOSED_CASE_SUCCESS = "FETCH_CLOSED_CASE_SUCCESS";
export const FETCH_CLOSED_CASE_FAILURE = "FETCH_CLOSED_CASE_FAILURE";

export const GET_SINGLE_DISPUTE_MESSAGES_START =
  "GET_SINGLE_DISPUTE_MESSAGES_START";
export const GET_SINGLE_DISPUTE_MESSAGES_SUCCESS =
  "GET_SINGLE_DISPUTE_MESSAGES_SUCCESS";
export const GET_SINGLE_DISPUTE_MESSAGES_FAILURE =
  "GET_SINGLE_DISPUTE_MESSAGES_FAILURE";

export const SEND_DISPUTE_MESSAGE_START = "SEND_DISPUTE_MESSAGE_START";
export const SEND_DISPUTE_MESSAGE_SUCCESS = "SEND_DISPUTE_MESSAGE_SUCCESS";
export const SEND_DISPUTE_MESSAGE_FAILURE = "SEND_DISPUTE_MESSAGE_FAILURE";

export const CANCEL_DISPUTE_REQUEST_START = "CANCEL_DISPUTE_REQUEST_START";
export const CANCEL_DISPUTE_REQUEST_SUCCESS = "CANCEL_DISPUTE_REQUEST_SUCCESS";
export const CANCEL_DISPUTE_REQUEST_FAILURE = "CANCEL_DISPUTE_REQUEST_FAILURE";

export const FETCH_SINGLE_DISPUTE_START = "FETCH_SINGLE_DISPUTE_START";
export const FETCH_SINGLE_DISPUTE_SUCCESS = "FETCH_SINGLE_DISPUTE_SUCCESS";
export const FETCH_SINGLE_DISPUTE_FAILURE = "FETCH_SINGLE_DISPUTE_FAILURE";

// Registeration verify.

export const REGISTER_VERIFY_START = "REGISTER_VERIFY_START";
export const REGISTER_VERIFY_SUCCESS = "REGISTER_VERIFY_SUCCESS";
export const REGISTER_VERIFY_FAILURE = "REGISTER_VERIFY_FAILURE";

export const REGISTER_VERIFY_RESEND_START = "REGISTER_VERIFY_RESEND_START";
export const REGISTER_VERIFY_RESEND_SUCCESS = "REGISTER_VERIFY_RESEND_SUCCESS";
export const REGISTER_VERIFY_RESEND_FAILURE = "REGISTER_VERIFY_RESEND_FAILURE";

// Pages

export const FETCH_SINGLE_PAGE_START = "FETCH_SINGLE_PAGE_START";
export const FETCH_SINGLE_PAGE_SUCCESS = "FETCH_SINGLE_PAGE_SUCCESS";
export const FETCH_SINGLE_PAGE_FAILURE = "FETCH_SINGLE_PAGE_FAILURE";

// Notification on or off

export const NOTIFICATION_STATUS_UPDATE_START =
  "NOTIFICATION_STATUS_UPDATE_START";
export const NOTIFICATION_STATUS_UPDATE_SUCCESS =
  "NOTIFICATION_STATUS_UPDATE_SUCCESS";
export const NOTIFICATION_STATUS_UPDATE_FAILURE =
  "NOTIFICATION_STATUS_UPDATE_FAILURE";

// Error Handling for logout

export const ERROR_LOGOUT_CHECK = "ERROR_LOGOUT_CHECK";

// Product owner constant

export const SAVE_PRO_OWNER_START = "SAVE_PRO_OWNER_START";
export const SAVE_PRO_OWNER_SUCCESS = "SAVE_PRO_OWNER_SUCCESS";
export const SAVE_PRO_OWNER_FAILURE = "SAVE_PRO_OWNER_FAILURE";

export const FETCH_SINGLE_PRO_OWNER_START = "FETCH_SINGLE_PRO_OWNER_START";
export const FETCH_SINGLE_PRO_OWNER_SUCCESS = "FETCH_SINGLE_PRO_OWNER_SUCCESS";
export const FETCH_SINGLE_PRO_OWNER_FAILURE = "FETCH_SINGLE_PRO_OWNER_FAILURE";

export const SET_VISIBILITY_PRO_OWNER_START = "SET_VISIBILITY_PRO_OWNER_START";
export const SET_VISIBILITY_PRO_OWNER_SUCCESS =
  "SET_VISIBILITY_PRO_OWNER_SUCCESS";
export const SET_VISIBILITY_PRO_OWNER_FAILURE =
  "SET_VISIBILITY_PRO_OWNER_FAILURE";

export const UPDATE_AVAILABILITY_PRO_OWNER_START =
  "UPDATE_AVAILABILITY_PRO_OWNER_START";
export const UPDATE_AVAILABILITY_PRO_OWNER_SUCCESS =
  "UPDATE_AVAILABILITY_PRO_OWNER_SUCCESS";
export const UPDATE_AVAILABILITY_PRO_OWNER_FAILURE =
  "UPDATE_AVAILABILITY_PRO_OWNER_FAILURE";

export const FETCH_PRODUCTS_PRO_OWNER_START = "FETCH_PRODUCTS_PRO_OWNER_START";
export const FETCH_PRODUCTS_PRO_OWNER_SUCCESS =
  "FETCH_PRODUCTS_PRO_OWNER_SUCCESS";
export const FETCH_PRODUCTS_PRO_OWNER_FAILURE =
  "FETCH_PRODUCTS_PRO_OWNER_FAILURE";

export const FETCH_PRO_CATE_PRO_OWNER_START = "FETCH_PRO_CATE_PRO_OWNER_START";
export const FETCH_PRO_CATE_PRO_OWNER_SUCCESS =
  "FETCH_PRO_CATE_PRO_OWNER_SUCCESS";
export const FETCH_PRO_CATE_PRO_OWNER_FAILURE =
  "FETCH_PRO_CATE_PRO_OWNER_FAILURE";

export const FETCH_PRO_SUBCATE_PRO_OWNER_START =
  "FETCH_PRO_SUBCATE_PRO_OWNER_START";
export const FETCH_PRO_SUBCATE_PRO_OWNER_SUCCESS =
  "FETCH_PRO_SUBCATE_PRO_OWNER_SUCCESS";
export const FETCH_PRO_SUBCATE_PRO_OWNER_FAILURE =
  "FETCH_PRO_SUBCATE_PRO_OWNER_FAILURE";

export const PRO_SEARCH_PRO_OWNER_START = "PRO_SEARCH_PRO_OWNER_START";
export const PRO_SEARCH_PRO_OWNER_SUCCESS = "PRO_SEARCH_PRO_OWNER_SUCCESS";
export const PRO_SEARCH_PRO_OWNER_FAILURE = "PRO_SEARCH_PRO_OWNER_FAILURE";

export const FETCH_PRO_IMAGE_PRO_OWNER_START =
  "FETCH_PRO_IMAGE_PRO_OWNER_START";
export const FETCH_PRO_IMAGE_PRO_OWNER_SUCCESS =
  "FETCH_PRO_IMAGE_PRO_OWNER_SUCCESS";
export const FETCH_PRO_IMAGE_PRO_OWNER_FAILURE =
  "FETCH_PRO_IMAGE_PRO_OWNER_FAILURE";

export const SAVE_PRO_IMAGE_PRO_OWNER_START = "SAVE_PRO_IMAGE_PRO_OWNER_START";
export const SAVE_PRO_IMAGE_PRO_OWNER_SUCCESS =
  "SAVE_PRO_IMAGE_PRO_OWNER_SUCCESS";
export const SAVE_PRO_IMAGE_PRO_OWNER_FAILURE =
  "SAVE_PRO_IMAGE_PRO_OWNER_FAILURE";

export const DELETE_PRO_IMAGE_PRO_OWNER_START =
  "DELETE_PRO_IMAGE_PRO_OWNER_START";
export const DELETE_PRO_IMAGE_PRO_OWNER_SUCCESS =
  "DELETE_PRO_IMAGE_PRO_OWNER_SUCCESS";
export const DELETE_PRO_IMAGE_PRO_OWNER_FAILURE =
  "DELETE_PRO_IMAGE_PRO_OWNER_FAILURE";

// Post constants

export const SAVE_POST_START = "SAVE_POST_START";
export const SAVE_POST_SUCCESS = "SAVE_POST_SUCCESS";
export const SAVE_POST_FAILURE = "SAVE_POST_FAILURE";

export const FETCH_POSTS_START = "FETCH_POSTS_START";
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_POSTS_FAILURE = "FETCH_POSTS_FAILURE";

export const FETCH_SINGLE_POST_START = "FETCH_SINGLE_POST_START";
export const FETCH_SINGLE_POST_SUCCESS = "FETCH_SINGLE_POST_SUCCESS";
export const FETCH_SINGLE_POST_FAILURE = "FETCH_SINGLE_POST_FAILURE";

export const DELETE_POST_START = "DELETE_POST_START";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";

export const CHANGE_POST_STATUS_START = "CHANGE_POST_STATUS_START";
export const CHANGE_POST_STATUS_SUCCESS = "CHANGE_POST_STATUS_SUCCESS";
export const CHANGE_POST_STATUS_FAILURE = "CHANGE_POST_STATUS_FAILURE";

export const POST_FILE_UPLOAD_START = "POST_FILE_UPLOAD_START";
export const POST_FILE_UPLOAD_SUCCESS = "POST_FILE_UPLOAD_SUCCESS";
export const POST_FILE_UPLOAD_FAILURE = "POST_FILE_UPLOAD_FAILURE";

export const FETCH_EXPLORE_START = "FETCH_EXPLORE_START";
export const FETCH_EXPLORE_SUCCESS = "FETCH_EXPLORE_SUCCESS";
export const FETCH_EXPLORE_FAILURE = "FETCH_EXPLORE_FAILURE";
export const POST_FILE_REMOVE_START = "POST_FILE_REMOVE_START";
export const POST_FILE_REMOVE_SUCCESS = "POST_FILE_REMOVE_SUCCESS";
export const POST_FILE_REMOVE_FAILURE = "POST_FILE_REMOVE_FAILURE";

export const FETCH_POST_CATEGORIES_START = " FETCH_POST_CATEGORIES_START";
export const FETCH_POST_CATEGORIES_SUCCESS = " FETCH_POST_CATEGORIES_SUCCESS";
export const FETCH_POST_CATEGORIES_FAILURE = " FETCH_POST_CATEGORIES_FAILURE";

export const FETCH_REPORT_REASON_START = " FETCH_REPORT_REASON_START";
export const FETCH_REPORT_REASON_SUCCESS = " FETCH_REPORT_REASON_SUCCESS";
export const FETCH_REPORT_REASON_FAILURE = " FETCH_REPORT_REASON_FAILURE";

export const POST_NFT_STATUS_UPDATE_START = "POST_NFT_STATUS_UPDATE_START";
export const POST_NFT_STATUS_UPDATE_SUCCESS = "POST_NFT_STATUS_UPDATE__SUCCESS";
export const POST_NFT_STATUS_UPDATE_FAILURE = "POST_NFT_STATUS_UPDATE__FAILURE";

export const SINGLE_POST_COMMON_DETAILS_START = "SINGLE_POST_COMMON_DETAILS_START";
export const SINGLE_POST_COMMON_DETAILS_SUCCESS ="SINGLE_POST_COMMEON_DETAILS_SUCCESS";
export const SINGLE_POST_COMMON_DETAILS_FAILURE ="SINGLE_POST_COMMON_DETAILS_FAILURE";

// Follow constants

export const FOLLOW_USER_START = "FOLLOW_USER_START";
export const FOLLOW_USER_SUCCESS = "FOLLOW_USER_SUCCESS";
export const FOLLOW_USER_FAILURE = "FOLLOW_USER_FAILURE";

export const UNFOLLOW_USER_START = "UNFOLLOW_USER_START";
export const UNFOLLOW_USER_SUCCESS = "UNFOLLOW_USER_SUCCESS";
export const UNFOLLOW_USER_FAILURE = "UNFOLLOW_USER_FAILURE";

export const FETCH_FOLLOWERS_START = "FETCH_FOLLOWERS_START";
export const FETCH_FOLLOWERS_SUCCESS = "FETCH_FOLLOWERS_SUCCESS";
export const FETCH_FOLLOWERS_FAILURE = "FETCH_FOLLOWERS_FAILURE";

export const FETCH_ACTIVE_FOLLOWERS_START = "FETCH_ACTIVE_FOLLOWERS_START";
export const FETCH_ACTIVE_FOLLOWERS_SUCCESS = "FETCH_ACTIVE_FOLLOWERS_SUCCESS";
export const FETCH_ACTIVE_FOLLOWERS_FAILURE = "FETCH_ACTIVE_FOLLOWERS_FAILURE";

export const FETCH_EXPIRED_FOLLOWERS_START = "FETCH_EXPIRED_FOLLOWERS_START";
export const FETCH_EXPIRED_FOLLOWERS_SUCCESS =
  "FETCH_EXPIRED_FOLLOWERS_SUCCESS";
export const FETCH_EXPIRED_FOLLOWERS_FAILURE =
  "FETCH_EXPIRED_FOLLOWERS_FAILURE";

export const FETCH_FOLLOWING_START = "FETCH_FOLLOWING_START";
export const FETCH_FOLLOWING_SUCCESS = "FETCH_FOLLOWING_SUCCESS";
export const FETCH_FOLLOWING_FAILURE = "FETCH_FOLLOWING_FAILURE";

export const FETCH_ACTIVE_FOLLOWING_START = "FETCH_ACTIVE_FOLLOWING_START";
export const FETCH_ACTIVE_FOLLOWING_SUCCESS = "FETCH_ACTIVE_FOLLOWING_SUCCESS";
export const FETCH_ACTIVE_FOLLOWING_FAILURE = "FETCH_ACTIVE_FOLLOWING_FAILURE";

export const FETCH_EXPIRED_FOLLOWING_START = "FETCH_EXPIRED_FOLLOWING_START";
export const FETCH_EXPIRED_FOLLOWING_SUCCESS =
  "FETCH_EXPIRED_FOLLOWING_SUCCESS";
export const FETCH_EXPIRED_FOLLOWING_FAILURE =
  "FETCH_EXPIRED_FOLLOWING_FAILURE";

// Document verification

export const FETCH_VERI_DOCUMENT_START = "FETCH_VERI_DOCUMENT_START";
export const FETCH_VERI_DOCUMENT_SUCCESS = "FETCH_VERI_DOCUMENT_SUCCESS";
export const FETCH_VERI_DOCUMENT_FAILURE = "FETCH_VERI_DOCUMENT_FAILURE";

export const SAVE_VERI_DOC_START = "SAVE_VERI_DOC_START";
export const SAVE_VERI_DOC_SUCCESS = "SAVE_VERI_DOC_SUCCESS";
export const SAVE_VERI_DOC_FAILURE = "SAVE_VERI_DOC_FAILURE";

export const DEL_VERI_DOC_START = "DEL_VERI_DOC_START";
export const DEL_VERI_DOC_SUCCESS = "DEL_VERI_DOC_SUCCESS";
export const DEL_VERI_DOC_FAILURE = "DEL_VERI_DOC_FAILURE";

export const VERI_STATUS_CHECK_START = "VERI_STATUS_CHECK_START";
export const VERI_STATUS_CHECK_SUCCESS = "VERI_STATUS_CHECK_SUCCESS";
export const VERI_STATUS_CHECK_FAILURE = "VERI_STATUS_CHECK_FAILURE";

// Comments constant

export const FETCH_COMMENTS_START = "FETCH_COMMENTS_START";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAILURE = "FETCH_COMMENTS_FAILURE";
export const FETCH_MORE_COMMENTS_START = "FETCH_MORE_COMMENTS_START";

export const SAVE_COMMENT_START = "SAVE_COMMENT_START";
export const SAVE_COMMENT_SUCCESS = "SAVE_COMMENT_SUCCESS";
export const SAVE_COMMENT_FAILURE = "SAVE_COMMENT_FAILURE";

export const DELETE_COMMENT_START = "DELETE_COMMENT_START";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";

export const FETCH_COMMENT_REPLIES_START = "FETCH_COMMENT_REPLIES_START";
export const FETCH_COMMENT_REPLIES_SUCCESS = "FETCH_COMMENT_REPLIES_SUCCESS";
export const FETCH_COMMENT_REPLIES_FAILURE = "FETCH_COMMENT_REPLIES_FAILURE";
export const FETCH_MORE_COMMENT_REPLIES_START = "FETCH_MORE_COMMENT_REPLIES_START";

export const SAVE_COMMENT_REPLY_START = "SAVE_COMMENT_REPLY_START";
export const SAVE_COMMENT_REPLY_SUCCESS = "SAVE_COMMENT_REPLY_SUCCESS";
export const SAVE_COMMENT_REPLY_FAILURE = "SAVE_COMMENT_REPLY_FAILURE";

// Fav constant

export const FETCH_FAV_START = "FETCH_FAV_START";
export const FETCH_FAV_SUCCESS = "FETCH_FAV_SUCCESS";
export const FETCH_FAV_FAILURE = "FETCH_FAV_FAILURE";

export const SAVE_FAV_START = "SAVE_FAV_START";
export const SAVE_FAV_SUCCESS = "SAVE_FAV_SUCCESS";
export const SAVE_FAV_FAILURE = "SAVE_FAV_FAILURE";

export const DELETE_FAV_START = "DELETE_FAV_START";
export const DELETE_FAV_SUCCESS = "DELETE_FAV_SUCCESS";
export const DELETE_FAV_FAILURE = "DELETE_FAV_FAILURE";

// Bookmark Management constant

export const FETCH_BOOKMARKS_START = "FETCH_BOOKMARKS_START";
export const FETCH_BOOKMARKS_SUCCESS = "FETCH_BOOKMARKS_SUCCESS";
export const FETCH_BOOKMARKS_FAILURE = "FETCH_BOOKMARKS_FAILURE";

export const FETCH_BOOKMARKS_PHOTO_START = "FETCH_BOOKMARKS_PHOTO_START";
export const FETCH_BOOKMARKS_PHOTO_SUCCESS = "FETCH_BOOKMARKS_PHOTO_SUCCESS";
export const FETCH_BOOKMARKS_PHOTO_FAILURE = "FETCH_BOOKMARKS_PHOTO_FAILURE";

export const FETCH_BOOKMARKS_VIDEO_START = "FETCH_BOOKMARKS_VIDEO_START";
export const FETCH_BOOKMARKS_VIDEO_SUCCESS = "FETCH_BOOKMARKS_VIDEO_SUCCESS";
export const FETCH_BOOKMARKS_VIDEO_FAILURE = "FETCH_BOOKMARKS_VIDEO_FAILURE";

export const SAVE_BOOKMARK_START = "SAVE_BOOKMARK_START";
export const SAVE_BOOKMARK_SUCCESS = "SAVE_BOOKMARK_SUCCESS";
export const SAVE_BOOKMARK_FAILURE = "SAVE_BOOKMARK_FAILURE";

export const DELETE_BOOKMARK_START = "DELETE_BOOKMARK_START";
export const DELETE_BOOKMARK_SUCCESS = "DELETE_BOOKMARK_SUCCESS";
export const DELETE_BOOKMARK_FAILURE = "DELETE_BOOKMARK_FAILURE";

// Send Tip constant

export const SEND_TIP_BY_STRIPE_START = "SEND_TIP_BY_STRIPE_START";
export const SEND_TIP_BY_STRIPE_SUCCESS = "SEND_TIP_BY_STRIPE_SUCCESS";
export const SEND_TIP_BY_STRIPE_FAILURE = "SEND_TIP_BY_STRIPE_FAILURE";

export const SEND_TIP_BY_WALLET_START = "SEND_TIP_BY_WALLET_START";
export const SEND_TIP_BY_WALLET_SUCCESS = "SEND_TIP_BY_WALLET_SUCCESS";
export const SEND_TIP_BY_WALLET_FAILURE = "SEND_TIP_BY_WALLET_FAILURE";

// Home constants

export const FETCH_HOME_POSTS_START = "FETCH_HOME_POSTS_START";
export const FETCH_HOME_POSTS_SUCCESS = "FETCH_HOME_POSTS_SUCCESS";
export const HOME_POSTS_SUCCESS = "HOME_POSTS_SUCCESS";
export const FETCH_HOME_POSTS_FAILURE = "FETCH_HOME_POSTS_FAILURE";
export const FETCH_MORE_HOME_POSTS_START = "FETCH_MORE_HOME_POSTS_START";

export const SEARCH_USER_START = "SEARCH_USER_START";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_FAILURE = "SEARCH_USER_FAILURE";

export const FETCH_OTHERS_SINGLE_POST_START = "FETCH_OTHERS_SINGLE_POST_START";
export const FETCH_OTHERS_SINGLE_POST_SUCCESS =
  "FETCH_OTHERS_SINGLE_POST_SUCCESS";
export const FETCH_OTHERS_SINGLE_POST_FAILURE =
  "FETCH_OTHERS_SINGLE_POST_FAILURE";

export const FETCH_POST_SUGGESTION_START = "FETCH_POST_SUGGESTION_START";
export const FETCH_POST_SUGGESTION_SUCCESS = "FETCH_POST_SUGGESTION_SUCCESS";
export const FETCH_POST_SUGGESTION_FAILURE = "FETCH_POST_SUGGESTION_FAILURE";

export const FETCH_TRENDING_USERS_START = "FETCH_TRENDING_USERS_START";
export const FETCH_TRENDING_USERS_SUCCESS = "FETCH_TRENDING_USERS_SUCCESS";
export const FETCH_TRENDING_USERS_FAILURE = "FETCH_TRENDING_USERS_FAILURE";

export const POST_PAYMENT_STRIPE_START = "POST_PAYMENT_STRIPE_START";
export const POST_PAYMENT_STRIPE_SUCCESS = "POST_PAYMENT_STRIPE_SUCCESS";
export const POST_PAYMENT_STRIPE_FAILURE = "POST_PAYMENT_STRIPE_FAILURE";

export const POST_PAYMENT_WALLET_START = "POST_PAYMENT_WALLET_START";
export const POST_PAYMENT_WALLET_SUCCESS = "POST_PAYMENT_WALLET_SUCCESS";
export const POST_PAYMENT_WALLET_FAILURE = "POST_PAYMENT_WALLET_FAILURE";

export const FETCH_LISTS_DETAILS_START = "FETCH_LISTS_DETAILS_START";
export const FETCH_LISTS_DETAILS_SUCCESS = "FETCH_LISTS_DETAILS_SUCCESS";
export const FETCH_LISTS_DETAILS_FAILURE = "FETCH_LISTS_DETAILS_FAILURE";

/// Model single profile and post

export const FETCH_SINGLE_USER_PROFILE_START =
  "FETCH_SINGLE_USER_PROFILE_START";
export const FETCH_SINGLE_USER_PROFILE_SUCCESS =
  "FETCH_SINGLE_USER_PROFILE_SUCCESS";
export const FETCH_SINGLE_USER_PROFILE_FAILURE =
  "FETCH_SINGLE_USER_PROFILE_FAILURE";

export const FETCH_SINGLE_USER_POSTS_START = "FETCH_SINGLE_USER_POSTS_START";
export const FETCH_SINGLE_USER_POSTS_SUCCESS =
  "FETCH_SINGLE_USER_POSTS_SUCCESS";
export const FETCH_SINGLE_USER_POSTS_FAILURE =
  "FETCH_SINGLE_USER_POSTS_FAILURE";

export const SAVE_POST_LIKE_START = "SAVE_POST_LIKE_START";
export const SAVE_POST_LIKE_SUCCESS = "SAVE_POST_LIKE_SUCCESS";
export const SAVE_POST_LIKE_FAILURE = "SAVE_POST_LIKE_FAILURE";

export const FETCH_POST_LIKED_START = "FETCH_POST_LIKED_START";
export const FETCH_POST_LIKED_SUCCESS = "FETCH_POST_LIKED_SUCCESS";
export const FETCH_POST_LIKED_FAILURE = "FETCH_POST_LIKED_FAILURE";

// Chat constant

export const FETCH_CHAT_USERS_START = "FETCH_CHAT_USERS_START";
export const FETCH_CHAT_USERS_SUCCESS = "FETCH_CHAT_USERS_SUCCESS";
export const FETCH_CHAT_USERS_FAILURE = "FETCH_CHAT_USERS_FAILURE";

export const ADD_MESSAGE_CONTENT = "ADD_MESSAGE_CONTENT";
export const ADD_MESSAGE_CONTENT_START = "ADD_MESSAGE_CONTENT_START";
export const ADD_MESSAGE_CONTENT_SUCCESS = "ADD_MESSAGE_CONTENT_SUCCESS";

export const FETCH_CHAT_MESSAGE_START = "FETCH_CHAT_MESSAGE_START";
export const FETCH_CHAT_MESSAGE_SUCCESS = "FETCH_CHAT_MESSAGE_SUCCESS";
export const FETCH_CHAT_MESSAGE_FAILURE = "FETCH_CHAT_MESSAGE_FAILURE";

export const SAVE_CHAT_USERS_START = "SAVE_CHAT_USERS_START";
export const SAVE_CHAT_USERS_SUCCESS = "SAVE_CHAT_USERS_SUCCESS";
export const SAVE_CHAT_USERS_FAILURE = "SAVE_CHAT_USERS_FAILURE";

// Notification constant

export const FETCH_NOTIFICATIONS_START = "FETCH_NOTIFICATIONS_START";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

export const PPV_PAYMENT_STRIPE_START = "PPV_PAYMENT_STRIPE_START";
export const PPV_PAYMENT_STRIPE_SUCCESS = "PPV_PAYMENT_STRIPE_SUCCESS";
export const PPV_PAYMENT_STRIPE_FAILURE = "PPV_PAYMENT_STRIPE_FAILURE";

export const PPV_PAYMENT_WALLET_START = "PPV_PAYMENT_WALLET_START";
export const PPV_PAYMENT_WALLET_SUCCESS = "PPV_PAYMENT_WALLET_SUCCESS";
export const PPV_PAYMENT_WALLET_FAILURE = "PPV_PAYMENT_WALLET_FAILURE";

export const SAVE_BLOCK_USER_START = "SAVE_BLOCK_USER_START";
export const SAVE_BLOCK_USER_SUCCESS = "SAVE_BLOCK_USER_SUCCESS";
export const SAVE_BLOCK_USER_FAILURE = "SAVE_BLOCK_USER_FAILURE";

export const FETCH_BLOCK_USERS_START = "FETCH_BLOCK_USERS_START";
export const FETCH_BLOCK_USERS_SUCCESS = "FETCH_BLOCK_USERS_SUCCESS";
export const FETCH_BLOCK_USERS_FAILURE = "FETCH_BLOCK_USERS_FAILURE";

export const SAVE_REPORT_POST_START = "SAVE_REPORT_POST_START";
export const SAVE_REPORT_POST_SUCCESS = "SAVE_REPORT_POST_SUCCESS";
export const SAVE_REPORT_POST_FAILURE = "SAVE_REPORT_POST_FAILURE";

export const FETCH_REPORT_POSTS_START = "FETCH_REPORT_POSTS_START";
export const FETCH_REPORT_POSTS_SUCCESS = "FETCH_REPORT_POSTS_SUCCESS";
export const FETCH_REPORT_POSTS_FAILURE = "FETCH_REPORT_POSTS_FAILURE";

export const SUBSCRIPTION_PAYMENT_PAYPAL_START =
  "SUBSCRIPTION_PAYMENT_PAYPAL_START";
export const SUBSCRIPTION_PAYMENT_PAYPAL_SUCCESS =
  "SUBSCRIPTION_PAYMENT_PAYPAL_SUCCESS";
export const SUBSCRIPTION_PAYMENT_PAYPAL_FAILURE =
  "SUBSCRIPTION_PAYMENT_PAYPAL_FAILURE";

export const PPV_PAYMENT_PAYPAL_START = "PPV_PAYMENT_PAYPAL_START";
export const PPV_PAYMENT_PAYPAL_SUCCESS = "PPV_PAYMENT_PAYPAL_SUCCESS";
export const PPV_PAYMENT_PAYPAL_FAILURE = "PPV_PAYMENT_PAYPAL_FAILURE";

export const SEND_TIP_BY_PAYPAL_START = "SEND_TIP_BY_PAYPAL_START";
export const SEND_TIP_BY_PAYPAL_SUCCESS = "SEND_TIP_BY_PAYPAL_SUCCESS";
export const SEND_TIP_BY_PAYPAL_FAILURE = "SEND_TIP_BY_PAYPAL_FAILURE";

export const SUBSCRIPTION_PAYMENT_CCBILL_START =
  "SUBSCRIPTION_PAYMENT_CCBILL_START";
export const SUBSCRIPTION_PAYMENT_CCBILL_SUCCESS =
  "SUBSCRIPTION_PAYMENT_CCBILL_SUCCESS";
export const SUBSCRIPTION_PAYMENT_CCBILL_FAILURE =
  "SUBSCRIPTION_PAYMENT_CCBILL_FAILURE";

export const PPV_PAYMENT_CCBILL_START = "PPV_PAYMENT_CCBILL_START";
export const PPV_PAYMENT_CCBILL_SUCCESS = "PPV_PAYMENT_CCBILL_SUCCESS";
export const PPV_PAYMENT_CCBILL_FAILURE = "PPV_PAYMENT_CCBILL_FAILURE";

export const SEND_TIP_BY_CCBILL_START = "SEND_TIP_BY_CCBILL_START";
export const SEND_TIP_BY_CCBILL_SUCCESS = "SEND_TIP_BY_CCBILL_SUCCESS";
export const SEND_TIP_BY_CCBILL_FAILURE = "SEND_TIP_BY_CCBILL_FAILURE";

export const USER_VERIFY_BADGE_STATUS_START = "USER_VERIFY_BADGE_STATUS_START";
export const USER_VERIFY_BADGE_STATUS_SUCCESS =
  "USER_VERIFY_BADGE_STATUS_SUCCESS";
export const USER_VERIFY_BADGE_STATUS_FAILURE =
  "USER_VERIFY_BADGE_STATUS_FAILURE";

// Categories constants

export const UPDATE_CATEGORY_START = "UPDATE_CATEGORY_START";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

export const FETCH_CATEGORIES_START = "FETCH_CATEGORIES_START";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";

export const FETCH_CATEGORY_USERS_START = "FETCH_CATEGORY_USERS_START";
export const FETCH_CATEGORY_USERS_SUCCESS = "FETCH_CATEGORY_USERS_SUCCESS";
export const FETCH_CATEGORY_USERS_FAILURE = "FETCH_CATEGORY_USERS_FAILURE";

export const FOLLOW_CATEGORY_START = "FOLLOW_CATEGORY_START";
export const FOLLOW_CATEGORY_SUCCESS = "FOLLOW_CATEGORY_SUCCESS";
export const FOLLOW_CATEGORY_FAILURE = "FOLLOW_CATEGORY_FAILURE";
export const CHAT_ASSET_PAYMENT_STRIPE_START =
  "CHAT_ASSET_PAYMENT_STRIPE_START";
export const CHAT_ASSET_PAYMENT_STRIPE_SUCCESS =
  "CHAT_ASSET_PAYMENT_STRIPE_SUCCESS";
export const CHAT_ASSET_PAYMENT_STRIPE_FAILURE =
  "CHAT_ASSET_PAYMENT_STRIPE_FAILURE";

export const CHAT_ASSET_PAYMENT_WALLET_START =
  "CHAT_ASSET_PAYMENT_WALLET_START";
export const CHAT_ASSET_PAYMENT_WALLET_SUCCESS =
  "CHAT_ASSET_PAYMENT_WALLET_SUCCESS";
export const CHAT_ASSET_PAYMENT_WALLET_FAILURE =
  "CHAT_ASSET_PAYMENT_WALLET_FAILURE";

export const CHAT_ASSET_PAYMENT_PAYPAL_START =
  "CHAT_ASSET_PAYMENT_PAYPAL_START";
export const CHAT_ASSET_PAYMENT_PAYPAL_SUCCESS =
  "CHAT_ASSET_PAYMENT_PAYPAL_SUCCESS";
export const CHAT_ASSET_PAYMENT_PAYPAL_FAILURE =
  "CHAT_ASSET_PAYMENT_PAYPAL_FAILURE";

export const UPLOAD_ASSET_DETAILS = "UPLOAD_ASSET_DETAILS";

export const CHAT_ASSET_FILE_UPLOAD_START = "CHAT_ASSET_FILE_UPLOAD_START";
export const CHAT_ASSET_FILE_UPLOAD_SUCCESS = "CHAT_ASSET_FILE_UPLOAD_SUCCESS";
export const CHAT_ASSET_FILE_UPLOAD_FAILURE = "CHAT_ASSET_FILE_UPLOAD_FAILURE";

export const FETCH_CHAT_ASSETS_START = "FETCH_CHAT_ASSETS_START";
export const FETCH_CHAT_ASSETS_SUCCESS = "FETCH_CHAT_ASSETS_SUCCESS";
export const FETCH_CHAT_ASSETS_FAILURE = "FETCH_CHAT_ASSETS_FAILURE";
// Reset Password
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const USERNAME_VALIDATION_START = "USERNAME_VALIDATION_START";
export const USERNAME_VALIDATION_SUCCESS = "USERNAME_VALIDATION_SUCCESS";
export const USERNAME_VALIDATION_FAILURE = "USERNAME_VALIDATION_FAILURE";

export const SEARCH_USER_POST_START = "SEARCH_USER_POST_START";
export const SEARCH_USER_POST_SUCCESS = "SEARCH_USER_POST_SUCCESS";
export const SEARCH_USER_POST_FAILURE = "SEARCH_USER_POST_FAILURE";

export const FETCH_VIDEO_CALL_REQUESTS_START =
  "FETCH_VIDEO_CALL_REQUESTS_START";
export const FETCH_VIDEO_CALL_REQUESTS_SUCCESS =
  "FETCH_VIDEO_CALL_REQUESTS_SUCCESS";
export const FETCH_VIDEO_CALL_REQUESTS_FAILURE =
  "FETCH_VIDEO_CALL_REQUESTS_FAILURE";

  export const FETCH_AUDIO_CALL_REQUESTS_START =
  "FETCH_AUDIO_CALL_REQUESTS_START";
  export const FETCH_MORE_AUDIO_CALL_REQUESTS_START =
  "FETCH_MORE_AUDIO_CALL_REQUESTS_START";
export const FETCH_AUDIO_CALL_REQUESTS_SUCCESS =
  "FETCH_AUDIO_CALL_REQUESTS_SUCCESS";
export const FETCH_AUDIO_CALL_REQUESTS_FAILURE =
  "FETCH_AUDIO_CALL_REQUESTS_FAILURE";

export const SAVE_VIDEO_CALL_REQUEST_START = "SAVE_VIDEO_CALL_REQUEST_START";
export const SAVE_VIDEO_CALL_REQUEST_SUCCESS =
  "SAVE_VIDEO_CALL_REQUEST_SUCCESS";
export const SAVE_VIDEO_CALL_REQUEST_FAILURE =
  "SAVE_VIDEO_CALL_REQUEST_FAILURE";

export const VIDEO_CALL_REQUESTS_ACCEPT_START =
  "VIDEO_CALL_REQUESTS_ACCEPT_START";
export const VIDEO_CALL_REQUESTS_ACCEPT_SUCCESS =
  "VIDEO_CALL_REQUESTS_ACCEPT_SUCCESS";
export const VIDEO_CALL_REQUESTS_ACCEPT_FAILURE =
  "VIDEO_CALL_REQUESTS_ACCEPT_FAILURE";

export const VIDEO_CALL_REQUESTS_REJECT_START =
  "VIDEO_CALL_REQUESTS_REJECT_START";
export const VIDEO_CALL_REQUESTS_REJECT_SUCCESS =
  "VIDEO_CALL_REQUESTS_REJECT_SUCCESS";
export const VIDEO_CALL_REQUESTS_REJECT_FAILURE =
  "VIDEO_CALL_REQUESTS_REJECT_FAILURE";

export const VIDEO_CALL_REQUESTS_JOIN_START = "VIDEO_CALL_REQUESTS_JOIN_START";
export const VIDEO_CALL_REQUESTS_JOIN_SUCCESS =
  "VIDEO_CALL_REQUESTS_JOIN_SUCCESS";
export const VIDEO_CALL_REQUESTS_JOIN_FAILURE =
  "VIDEO_CALL_REQUESTS_JOIN_FAILURE";

export const VIDEO_CALL_REQUESTS_PAYMENT_STRIPE_START =
  "VIDEO_CALL_REQUESTS_PAYMENT_STRIPE_START";
export const VIDEO_CALL_REQUESTS_PAYMENT_STRIPE_SUCCESS =
  "VIDEO_CALL_REQUESTS_PAYMENT_STRIPE_SUCCESS";
export const VIDEO_CALL_REQUESTS_PAYMENT_STRIPE_FAILURE =
  "VIDEO_CALL_REQUESTS_PAYMENT_STRIPE_FAILURE";

export const VIDEO_CALL_REQUESTS_PAYMENT_PAYPAL_START =
  "VIDEO_CALL_REQUESTS_PAYMENT_PAYPAL_START";
export const VIDEO_CALL_REQUESTS_PAYMENT_PAYPAL_SUCCESS =
  "VIDEO_CALL_REQUESTS_PAYMENT_PAYPAL_SUCCESS";
export const VIDEO_CALL_REQUESTS_PAYMENT_PAYPAL_FAILURE =
  "VIDEO_CALL_REQUESTS_PAYMENT_PAYPAL_FAILURE";

export const SAVE_VIDEO_CALL_AMOUNT_START = "SAVE_VIDEO_CALL_AMOUNT_START";
export const SAVE_VIDEO_CALL_AMOUNT_SUCCESS = "SAVE_VIDEO_CALL_AMOUNT_SUCCESS";
export const SAVE_VIDEO_CALL_AMOUNT_FAILURE = "SAVE_VIDEO_CALL_AMOUNT_FAILURE";

export const VIDEO_CALL_BROADCAST_START = "VIDEO_CALL_BROADCAST_START";
export const VIDEO_CALL_BROADCAST_SUCCESS = "VIDEO_CALL_BROADCAST_SUCCESS";
export const VIDEO_CALL_BROADCAST_FAILURE = "VIDEO_CALL_BROADCAST_FAILURE";

export const FETCH_LIVE_VIDEOS_START = "FETCH_LIVE_VIDEOS_START";
export const FETCH_LIVE_VIDEOS_SUCCESS = "FETCH_LIVE_VIDEOS_SUCCESS";
export const FETCH_LIVE_VIDEOS_FAILURE = "FETCH_LIVE_VIDEOS_FAILURE";
export const FETCH_MORE_LIVE_VIDEOS_START = "FETCH_MORE_LIVE_VIDEOS_START";

export const FETCH_LIVE_VIDEOS_HISTORY_START =
  "FETCH_LIVE_VIDEOS_HISTORY_START";
export const FETCH_LIVE_VIDEOS_HISTORY_SUCCESS =
  "FETCH_LIVE_VIDEOS_HISTORY_SUCCESS";
export const FETCH_LIVE_VIDEOS_HISTORY_FAILURE =
  "FETCH_LIVE_VIDEOS_HISTORY_FAILURE";

export const FETCH_MORE_LIVE_VIDEOS_HISTORY_START = "FETCH_MORE_LIVE_VIDEOS_HISTORY_START";


export const JOIN_LIVE_VIDEOS_START = "JOIN_LIVE_VIDEOS_START";
export const JOIN_LIVE_VIDEOS_SUCCESS = "JOIN_LIVE_VIDEOS_SUCCESS";
export const JOIN_LIVE_VIDEOS_FAILURE = "JOIN_LIVE_VIDEOS_FAILURE";

export const FETCH_SINGLE_LIVE_VIDEOS_START = "FETCH_SINGLE_LIVE_VIDEOS_START";
export const FETCH_SINGLE_LIVE_VIDEOS_SUCCESS =
  "FETCH_SINGLE_LIVE_VIDEOS_SUCCESS";
export const FETCH_SINGLE_LIVE_VIDEOS_FAILURE =
  "FETCH_SINGLE_LIVE_VIDEOS_FAILURE";

export const LIVE_VIDEOS_PAYMENT_BY_STRIPE_START =
  "LIVE_VIDEOS_PAYMENT_BY_STRIPE_START";
export const LIVE_VIDEOS_PAYMENT_BY_STRIPE_SUCCESS =
  "LIVE_VIDEOS_PAYMENT_BY_STRIPE_SUCCESS";
export const LIVE_VIDEOS_PAYMENT_BY_STRIPE_FAILURE =
  "LIVE_VIDEOS_PAYMENT_BY_STRIPE_FAILURE";

export const LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START =
  "LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START";
export const LIVE_VIDEOS_PAYMENT_BY_PAYPAL_SUCCESS =
  "LIVE_VIDEOS_PAYMENT_BY_PAYPAL_SUCCESS";
export const LIVE_VIDEOS_PAYMENT_BY_PAYPAL_FAILURE =
  "LIVE_VIDEOS_PAYMENT_BY_PAYPAL_FAILURE";

export const LIVE_VIDEOS_VIEWER_UPDATE_START =
  "LIVE_VIDEOS_VIEWER_UPDATE_START";
export const LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS =
  "LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS";
export const LIVE_VIDEOS_VIEWER_UPDATE_FAILURE =
  "LIVE_VIDEOS_VIEWER_UPDATE_FAILURE";

export const LIVE_VIDEOS_END_START = "LIVE_VIDEOS_END_START";
export const LIVE_VIDEOS_END_SUCCESS = "LIVE_VIDEOS_END_SUCCESS";
export const LIVE_VIDEOS_END_FAILURE = "LIVE_VIDEOS_END_FAILURE";

export const FETCH_LIVE_VIDEOS_CHAT_MESSAGES_START = "FETCH_LIVE_VIDEOS_CHAT_MESSAGES_START";
export const FETCH_LIVE_VIDEOS_CHAT_MESSAGES_SUCCESS = "FETCH_LIVE_VIDEOS_CHAT_MESSAGES_SUCCESS";
export const FETCH_LIVE_VIDEOS_CHAT_MESSAGES_FAILURE = "FETCH_LIVE_VIDEOS_CHAT_MESSAGES_FAILURE";


// one to one live streaming or Video call.

export const REQUEST_CALL_START = "REQUEST_CALL_START";
export const REQUEST_CALL_SUCCESS = "REQUEST_CALL_SUCCESS";
export const REQUEST_CALL_FAILURE = "REQUEST_CALL_FAILURE";

export const CALL_REQUEST_SENT_USER_START = "CALL_REQUEST_SENT_USER_START";
export const CALL_REQUEST_SENT_USER_SUCCESS = "CALL_REQUEST_SENT_USER_SUCCESS";
export const CALL_REQUEST_SENT_USER_FAILURE = "CALL_REQUEST_SENT_USER_FAILURE";

export const CALL_HISTORY_USER_START = "CALL_HISTORY_USER_START";
export const CALL_HISTORY_USER_SUCCESS = "CALL_HISTORY_USER_SUCCESS";
export const CALL_HISTORY_USER_FAILURE = "CALL_HISTORY_USER_FAILURE";

export const CALL_HISTORY_MODEL_START = "CALL_HISTORY_MODEL_START";
export const CALL_HISTORY_MODEL_SUCCESS = "CALL_HISTORY_MODEL_SUCCESS";
export const CALL_HISTORY_MODEL_FAILURE = "CALL_HISTORY_MODEL_FAILURE";

export const CALL_REQUEST_RECEIVED_MODEL_START =
  "CALL_REQUEST_RECEIVED_MODEL_START";
export const CALL_REQUEST_RECEIVED_MODEL_SUCCESS =
  "CALL_REQUEST_RECEIVED_MODEL_SUCCESS";
export const CALL_REQUEST_RECEIVED_MODEL_FAILURE =
  "CALL_REQUEST_RECEIVED_MODEL_FAILURE";

  export const CALL_AUDIO_REQUEST_RECEIVED_MODEL_START =
  "CALL_AUDIO_REQUEST_RECEIVED_MODEL_START";
  export const CALL_MORE_AUDIO_REQUEST_RECEIVED_MODEL_START =
  "CALL_MORE_AUDIO_REQUEST_RECEIVED_MODEL_START";
export const CALL_AUDIO_REQUEST_RECEIVED_MODEL_SUCCESS =
  "CALL_AUDIO_REQUEST_RECEIVED_MODEL_SUCCESS";
export const CALL_AUDIO_REQUEST_RECEIVED_MODEL_FAILURE =
  "CALL_AUDIO_REQUEST_RECEIVED_MODEL_FAILURE";

export const ACCEPT_CALL_START = "ACCEPT_CALL_START";
export const ACCEPT_CALL_SUCCESS = "ACCEPT_CALL_SUCCESS";
export const ACCEPT_CALL_FAILURE = "ACCEPT_CALL_FAILURE";

export const REJECT_CALL_START = "REJECT_CALL_START";
export const REJECT_CALL_SUCCESS = "REJECT_CALL_SUCCESS";
export const REJECT_CALL_FAILURE = "REJECT_CALL_FAILURE";

export const PAY_BY_STRIPE_START = "PAY_BY_STRIPE_START";
export const PAY_BY_STRIPE_SUCCESS = "PAY_BY_STRIPE_SUCCESS";
export const PAY_BY_STRIPE_FAILURE = "PAY_BY_STRIPE_FAILURE";

export const PAY_BY_PAYPAL_START = "PAY_BY_PAYPAL_START";
export const PAY_BY_PAYPAL_SUCCESS = "PAY_BY_PAYPAL_SUCCESS";
export const PAY_BY_PAYPAL_FAILURE = "PAY_BY_PAYPAL_FAILURE";

export const VIDEO_CALL_PAY_BY_WALLET_START = "VIDEO_CALL_PAY_BY_WALLET_START";
export const VIDEO_CALL_PAY_BY_WALLET_SUCCESS =
  "VIDEO_CALL_PAY_BY_WALLET_SUCCESS";
export const VIDEO_CALL_PAY_BY_WALLET_FAILURE =
  "VIDEO_CALL_PAY_BY_WALLET_FAILURE";

export const AUDIO_CALL_PAY_BY_WALLET_START = "AUDIO_CALL_PAY_BY_WALLET_START";
export const AUDIO_CALL_PAY_BY_WALLET_SUCCESS =
  "AUDIO_CALL_PAY_BY_WALLET_SUCCESS";
export const AUDIO_CALL_PAY_BY_WALLET_FAILURE =
  "AUDIO_CALL_PAY_BY_WALLET_FAILURE";

export const JOIN_VIDEO_CALL_START = "JOIN_VIDEO_CALL_START";
export const JOIN_VIDEO_CALL_SUCCESS = "JOIN_VIDEO_CALL_SUCCESS";
export const JOIN_VIDEO_CALL_FAILURE = "JOIN_VIDEO_CALL_FAILURE";

export const END_VIDEO_CALL_START = "END_VIDEO_CALL_START";
export const END_VIDEO_CALL_SUCCESS = "END_VIDEO_CALL_SUCCESS";
export const END_VIDEO_CALL_FAILURE = "END_VIDEO_CALL_FAILURE";

export const FETCH_SINGLE_VIDEO_CALL_START = "FETCH_SINGLE_VIDEO_CALL_START";
export const FETCH_SINGLE_VIDEO_CALL_SUCCESS =
  "FETCH_SINGLE_VIDEO_CALL_SUCCESS";
export const FETCH_SINGLE_VIDEO_CALL_FAILURE =
  "FETCH_SINGLE_VIDEO_CALL_FAILURE";

export const SUBSCRIPTION_PAYMENT_COINPAYMENT_START =
  "SUBSCRIPTION_PAYMENT_COINPAYMENT_START";
export const SUBSCRIPTION_PAYMENT_COINPAYMENT_SUCCESS =
  "SUBSCRIPTION_PAYMENT_COINPAYMENT_SUCCESS";
export const SUBSCRIPTION_PAYMENT_COINPAYMENT_FAILURE =
  "SUBSCRIPTION_PAYMENT_COINPAYMENT_FAILURE";

export const PPV_PAYMENT_COINPAYMENT_START = "PPV_PAYMENT_COINPAYMENT_START";
export const PPV_PAYMENT_COINPAYMENT_SUCCESS =
  "PPV_PAYMENT_COINPAYMENT_SUCCESS";
export const PPV_PAYMENT_COINPAYMENT_FAILURE =
  "PPV_PAYMENT_COINPAYMENT_FAILURE";

export const SEND_TIP_BY_COINPAYMENT_START = "SEND_TIP_BY_COINPAYMENT_START";
export const SEND_TIP_BY_COINPAYMENT_SUCCESS =
  "SEND_TIP_BY_COINPAYMENT_SUCCESS";
export const SEND_TIP_BY_COINPAYMENT_FAILURE =
  "SEND_TIP_BY_COINPAYMENT_FAILURE";
export const GET_REFERRAL_START = "GET_REFERRAL_START";
export const GET_REFERRAL_SUCCESS = "GET_REFERRAL_SUCCESS";
export const GET_REFERRAL_FAILURE = "GET_REFERRAL_FAILURE";

export const REFERRAL_VALIDATION_START = "REFERRAL_VALIDATION_START";
export const REFERRAL_VALIDATION_SUCCESS = "REFERRAL_VALIDATION_SUCCESS";
export const REFERRAL_VALIDATION_FAILURE = "REFERRAL_VALIDATION_FAILURE";

export const FETCH_USER_CATEGORY_LIST_START = "FETCH_USER_CATEGORY_LIST_START";
export const FETCH_USER_CATEGORY_LIST_SUCCESS =
  "FETCH_USER_CATEGORY_LIST_SUCCESS";
export const FETCH_USER_CATEGORY_LIST_FAILURE =
  "FETCH_USER_CATEGORY_LIST_FAILURE";

export const FETCH_CONTENT_CREATOR_LIST_START =
  "FETCH_CONTENT_CREATOR_LIST_START";
export const FETCH_CONTENT_CREATOR_LIST_SUCCESS =
  "FETCH_CONTENT_CREATOR_LIST_SUCCESS";
export const FETCH_CONTENT_CREATOR_LIST_FAILURE =
  "FETCH_CONTENT_CREATOR_LIST_FAILURE";
export const ACCEPT_AUDIO_CALL_START = "ACCEPT_AUDIO_CALL_START";
export const ACCEPT_AUDIO_CALL_SUCCESS = "ACCEPT_AUDIO_CALL_SUCCESS";
export const ACCEPT_AUDIO_CALL_FAILURE = "ACCEPT_AUDIO_CALL_FAILURE";

export const AUDIO_CALL_HISTORY_USER_START = "AUDIO_CALL_HISTORY_USER_START";
export const AUDIO_CALL_HISTORY_USER_SUCCESS =
  "AUDIO_CALL_HISTORY_USER_SUCCESS";
export const AUDIO_CALL_HISTORY_USER_FAILURE =
  "AUDIO_CALL_HISTORY_USER_FAILURE";

export const REJECT_AUDIO_CALL_START = "REJECT_AUDIO_CALL_START";
export const REJECT_AUDIO_CALL_SUCCESS = "REJECT_AUDIO_CALL_SUCCESS";
export const REJECT_AUDIO_CALL_FAILURE = "REJECT_AUDIO_CALL_FAILURE";

export const REQUEST_AUDIO_CALL_START = "REQUEST_AUDIO_CALL_START";
export const REQUEST_AUDIO_CALL_SUCCESS = "REQUEST_AUDIO_CALL_SUCCESS";
export const REQUEST_AUDIO_CALL_FAILURE = "REQUEST_AUDIO_CALL_FAILURE";

export const PAY_AUDIO_CALL_BY_STRIPE_START = "PAY_AUDIO_CALL_BY_STRIPE_START";
export const PAY_AUDIO_CALL_BY_STRIPE_SUCCESS =
  "PAY_AUDIO_CALL_BY_STRIPE_SUCCESS";
export const PAY_AUDIO_CALL_BY_STRIPE_FAILURE =
  "PAY_AUDIO_CALL_BY_STRIPE_FAILURE";

export const PAY_AUDIO_CALL_BY_PAYPAL_START = "PAY_AUDIO_CALL_BY_PAYPAL_START";
export const PAY_AUDIO_CALL_BY_PAYPAL_SUCCESS =
  "PAY_AUDIO_CALL_BY_PAYPAL_SUCCESS";
export const PAY_AUDIO_CALL_BY_PAYPAL_FAILURE =
  "PAY_AUDIO_CALL_BY_PAYPAL_FAILURE";

export const FETCH_SINGLE_AUDIO_CALL_START = "FETCH_SINGLE_AUDIO_CALL_START";
export const FETCH_SINGLE_AUDIO_CALL_SUCCESS =
  "FETCH_SINGLE_AUDIO_CALL_SUCCESS";
export const FETCH_SINGLE_AUDIO_CALL_FAILURE =
  "FETCH_SINGLE_AUDIO_CALL_FAILURE";

export const END_AUDIO_CALL_START = "END_AUDIO_CALL_START";
export const END_AUDIO_CALL_SUCCESS = "END_AUDIO_CALL_SUCCESS";
export const END_AUDIO_CALL_FAILURE = "END_AUDIO_CALL_FAILURE";

export const JOIN_AUDIO_CALL_START = "JOIN_AUDIO_CALL_START";
export const JOIN_AUDIO_CALL_SUCCESS = "JOIN_AUDIO_CALL_SUCCESS";
export const JOIN_AUDIO_CALL_FAILURE = "JOIN_AUDIO_CALL_FAILURE";

export const FETCH_BOOKMARKS_AUDIO_START = "FETCH_BOOKMARKS_AUDIO_START";
export const FETCH_BOOKMARKS_AUDIO_SUCCESS = "FETCH_BOOKMARKS_AUDIO_SUCCESS";
export const FETCH_BOOKMARKS_AUDIO_FAILURE = "FETCH_BOOKMARKS_AUDIO_FAILURE";

export const FETCH_USER_STORIES_START = "FETCH_USER_STORIES_START";
export const FETCH_USER_STORIES_SUCCESS = "FETCH_USER_STORIES_SUCCESS";
export const FETCH_USER_STORIES_FAILURE = "FETCH_USER_STORIES_FAILURE";

export const STORY_FILE_UPLOAD_START = "STORY_FILE_UPLOAD_START";
export const STORY_FILE_UPLOAD_SUCCESS = "STORY_FILE_UPLOAD_SUCCESS";
export const STORY_FILE_UPLOAD_FAILURE = "STORY_FILE_UPLOAD_FAILURE";

export const FETCH_STORIES_START = "FETCH_STORIES_START";
export const FETCH_STORIES_SUCCESS = "FETCH_STORIES_SUCCESS";
export const FETCH_STORIES_FAILURE = "FETCH_STORIES_FAILURE";

export const STORY_FILE_DELETE_START = "STORY_FILE_DELETE_START";
export const STORY_FILE_DELETE_SUCCESS = "STORY_FILE_DELETE_SUCCESS";
export const STORY_FILE_DELETE_FAILURE = "STORY_FILE_DELETE_FAILURE";

// E-Comm
export const FETCH_USER_PRODUCTS_START = "FETCH_USER_PRODUCTS_START";
export const FETCH_USER_PRODUCTS_SUCCESS = "FETCH_USER_PRODUCTS_SUCCESS";
export const FETCH_USER_PRODUCTS_FAILURE = "FETCH_USER_PRODUCTS_FAILURE";

export const USER_PRODUCTS_SAVE_START = "USER_PRODUCTS_SAVE_START";
export const USER_PRODUCTS_SAVE_SUCCESS = "USER_PRODUCTS_SAVE_SUCCESS";
export const USER_PRODUCTS_SAVE_FAILURE = "USER_PRODUCTS_SAVE_FAILURE";

export const FETCH_USER_SINGLE_PRODUCT_START =
  "FETCH_USER_SINGLE_PRODUCT_START";
export const FETCH_USER_SINGLE_PRODUCT_SUCCESS =
  "FETCH_USER_SINGLE_PRODUCT_SUCCESS";
export const FETCH_USER_SINGLE_PRODUCT_FAILURE =
  "FETCH_USER_SINGLE_PRODUCT_FAILURE";

export const DELETE_USER_PRODUCT_START = "DELETE_USER_PRODUCT_START";
export const DELETE_USER_PRODUCT_SUCCESS = "DELETE_USER_PRODUCT_SUCCESS";
export const DELETE_USER_PRODUCT_FAILURE = "DELETE_USER_PRODUCT_FAILURE";

export const SET_USER_PRODUCT_VISIBILITY_START =
  "SET_USER_PRODUCT_VISIBILITY_START";
export const SET_USER_PRODUCT_VISIBILITY_SUCCESS =
  "SET_USER_PRODUCT_VISIBILITY_SUCCESS";
export const SET_USER_PRODUCT_VISIBILITY_FAILURE =
  "SET_USER_PRODUCT_VISIBILITY_FAILURE";

export const UPDATE_USER_PRODUCT_AVAILABILITY_START =
  "UPDATE_USER_PRODUCT_AVAILABILITY_START";
export const UPDATE_USER_PRODUCT_AVAILABILITY_SUCCESS =
  "UPDATE_USER_PRODUCT_AVAILABILITY_SUCCESS";
export const UPDATE_USER_PRODUCT_AVAILABILITY_FAILURE =
  "UPDATE_USER_PRODUCT_AVAILABILITY_FAILURE";

export const FETCH_PRODUCT_CATEGORIES_START = "FETCH_PRODUCT_CATEGORIES_START";
export const FETCH_PRODUCT_CATEGORIES_SUCCESS =
  "FETCH_PRODUCT_CATEGORIES_SUCCESS";
export const FETCH_PRODUCT_CATEGORIES_FAILURE =
  "FETCH_PRODUCT_CATEGORIES_FAILURE";

export const FETCH_PRODUCT_SUB_CATEGORIES_START =
  "FETCH_PRODUCT_SUB_CATEGORIES_START";
export const FETCH_PRODUCT_SUB_CATEGORIES_SUCCESS =
  "FETCH_PRODUCT_SUB_CATEGORIES_SUCCESS";
export const FETCH_PRODUCT_SUB_CATEGORIES_FAILURE =
  "FETCH_PRODUCT_SUB_CATEGORIES_FAILURE";

export const FETCH_CART_LIST_START = "FETCH_CART_LIST_START";
export const FETCH_CART_LIST_SUCCESS = "FETCH_CART_LIST_SUCCESS";
export const FETCH_CART_LIST_FAILURE = "FETCH_CART_LIST_FAILURE";

export const SAVE_CART_DETAILS_START = "SAVE_CART_DETAILS_START";
export const SAVE_CART_DETAILS_SUCCESS = "SAVE_CART_DETAILS_SUCCESS";
export const SAVE_CART_DETAILS_FAILURE = "SAVE_CART_DETAILS_FAILURE";

export const REMOVE_CART_DETAILS_START = "REMOVE_CART_DETAILS_START";
export const REMOVE_CART_DETAILS_SUCCESS = "REMOVE_CART_DETAILS_SUCCESS";
export const REMOVE_CART_DETAILS_FAILURE = "REMOVE_CART_DETAILS_FAILURE";

export const USER_PRODUCTS_SEARCH_START = "USER_PRODUCTS_SEARCH_START";
export const USER_PRODUCTS_SEARCH_SUCCESS = "USER_PRODUCTS_SEARCH_SUCCESS";
export const USER_PRODUCTS_SEARCH_FAILURE = "USER_PRODUCTS_SEARCH_FAILURE";

export const FETCH_USER_PRODUCT_PICTURES_START =
  "FETCH_USER_PRODUCT_PICTURES_START";
export const FETCH_USER_PRODUCT_PICTURES_SUCCESS =
  "FETCH_USER_PRODUCT_PICTURES_SUCCESS";
export const FETCH_USER_PRODUCT_PICTURES_FAILURE =
  "FETCH_USER_PRODUCT_PICTURES_FAILURE";

export const USER_PRODUCT_PICTURES_SAVE_START =
  "USER_PRODUCT_PICTURES_SAVE_START";
export const USER_PRODUCT_PICTURES_SAVE_SUCCESS =
  "USER_PRODUCT_PICTURES_SAVE_SUCCESS";
export const USER_PRODUCT_PICTURES_SAVE_FAILURE =
  "USER_PRODUCT_PICTURES_SAVE_FAILURE";

export const USER_PRODUCT_PICTURES_DELETE_START =
  "USER_PRODUCT_PICTURES_DELETE_START";
export const USER_PRODUCT_PICTURES_DELETE_SUCCESS =
  "USER_PRODUCT_PICTURES_DELETE_SUCCESS";
export const USER_PRODUCT_PICTURES_DELETE_FAILURE =
  "USER_PRODUCT_PICTURES_DELETE_FAILURE";

export const FETCH_ECOMM_HOME_START = "FETCH_ECOMM_HOME_START";
export const FETCH_ECOMM_HOME_SUCCESS = "FETCH_ECOMM_HOME_SUCCESS";
export const FETCH_ECOMM_HOME_FAILURE = "FETCH_ECOMM_HOME_FAILURE";

export const USER_PRODUCT_VIEW_FOR_OTHERS_START =
  "USER_PRODUCT_VIEW_FOR_OTHERS_START";
export const USER_PRODUCT_VIEW_FOR_OTHERS_SUCCESS =
  "USER_PRODUCT_VIEW_FOR_OTHERS_SUCCESS";
export const USER_PRODUCT_VIEW_FOR_OTHERS_FAILURE =
  "USER_PRODUCT_VIEW_FOR_OTHERS_FAILURE";

export const ORDERS_LIST_FOR_OTHERS_START =
  "ORDERS_LIST_FOR_OTHERS_START";
export const ORDERS_LIST_FOR_OTHERS_SUCCESS =
  "ORDERS_LIST_FOR_OTHERS_SUCCESS";
export const ORDERS_LIST_FOR_OTHERS_FAILURE =
  "ORDERS_LIST_FOR_OTHERS_FAILURE";

export const ORDERS_VIEW_FOR_OTHERS_START =
  "ORDERS_VIEW_FOR_OTHERS_START";
export const ORDERS_VIEW_FOR_OTHERS_SUCCESS =
  "ORDERS_VIEW_FOR_OTHERS_SUCCESS";
export const ORDERS_VIEW_FOR_OTHERS_FAILURE =
  "ORDERS_VIEW_FOR_OTHERS_FAILURE";

export const FETCH_USER_ORDER_PAYMENTS_START =
  "FETCH_USER_ORDER_PAYMENTS_START";
export const FETCH_USER_ORDER_PAYMENTS_SUCCESS =
  "FETCH_USER_ORDER_PAYMENTS_SUCCESS";
export const FETCH_USER_ORDER_PAYMENTS_FAILURE =
  "FETCH_USER_ORDER_PAYMENTS_FAILURE";

export const FETCH_DELIVERY_ADDRESS_START =
  "FETCH_DELIVERY_ADDRESS_START";
export const FETCH_DELIVERY_ADDRESS_SUCCESS =
  "FETCH_DELIVERY_ADDRESS_SUCCESS";
export const FETCH_DELIVERY_ADDRESS_FAILURE =
  "FETCH_DELIVERY_ADDRESS_FAILURE";

// VOD Videos
export const FETCH_VOD_VIDEOES_FOR_OWNER_START =
  "FETCH_VOD_VIDEOES_FOR_OWNER_START";
export const FETCH_VOD_VIDEOES_FOR_OWNER_SUCCESS =
  "FETCH_VOD_VIDEOES_FOR_OWNER_SUCCESS";
export const FETCH_VOD_VIDEOES_FOR_OWNER_FAILURE =
  "FETCH_VOD_VIDEOES_FOR_OWNER_FAILURE";

export const VOD_VIDEOES_SAVE_START = "VOD_VIDEOES_SAVE_START";
export const VOD_VIDEOES_SAVE_SUCCESS = "VOD_VIDEOES_SAVE_SUCCESS";
export const VOD_VIDEOES_SAVE_FAILURE = "VOD_VIDEOES_SAVE_FAILURE";

export const FETCH_SINGLE_VOD_VIDEOES_FOR_OWNER_START =
  "FETCH_SINGLE_VOD_VIDEOES_FOR_OWNER_START";
export const FETCH_SINGLE_VOD_VIDEOES_FOR_OWNER_SUCCESS =
  "FETCH_SINGLE_VOD_VIDEOES_FOR_OWNER_SUCCESS";
export const FETCH_SINGLE_VOD_VIDEOES_FOR_OWNER_FAILURE =
  "FETCH_SINGLE_VOD_VIDEOES_FOR_OWNER_FAILURE";

export const DELETE_VOD_VIDEOES_START = "DELETE_VOD_VIDEOES_START";
export const DELETE_VOD_VIDEOES_SUCCESS = "DELETE_VOD_VIDEOES_SUCCESS";
export const DELETE_VOD_VIDEOES_FAILURE = "DELETE_VOD_VIDEOES_FAILURE";

export const VOD_VIDEOES_FILES_UPLOAD_START = "VOD_VIDEOES_FILES_UPLOAD_START";
export const VOD_VIDEOES_FILES_UPLOAD_SUCCESS =
  "VOD_VIDEOES_FILES_UPLOAD_SUCCESS";
export const VOD_VIDEOES_FILES_UPLOAD_FAILURE =
  "VOD_VIDEOES_FILES_UPLOAD_FAILURE";

export const VOD_VIDEOES_FILES_REMOVE_START = "VOD_VIDEOES_FILES_REMOVE_START";
export const VOD_VIDEOES_FILES_REMOVE_SUCCESS =
  "VOD_VIDEOES_FILES_REMOVE_SUCCESS";
export const VOD_VIDEOES_FILES_REMOVE_FAILURE =
  "VOD_VIDEOES_FILES_REMOVE_FAILURE";

export const VOD_VIDEOES_PAYMENT_BY_WALLET_START =
  "VOD_VIDEOES_PAYMENT_BY_WALLET_START";
export const VOD_VIDEOES_PAYMENT_BY_WALLET_SUCCESS =
  "VOD_VIDEOES_PAYMENT_BY_WALLET_SUCCESS";
export const VOD_VIDEOES_PAYMENT_BY_WALLET_FAILURE =
  "VOD_VIDEOES_PAYMENT_BY_WALLET_FAILURE";

export const VOD_VIDEOES_PAYMENT_BY_STRIPE_START =
  "VOD_VIDEOES_PAYMENT_BY_STRIPE_START";
export const VOD_VIDEOES_PAYMENT_BY_STRIPE_SUCCESS =
  "VOD_VIDEOES_PAYMENT_BY_STRIPE_SUCCESS";
export const VOD_VIDEOES_PAYMENT_BY_STRIPE_FAILURE =
  "VOD_VIDEOES_PAYMENT_BY_STRIPE_FAILURE";

export const VOD_VIDEOES_PAYMENT_BY_PAYPAL_START =
  "VOD_VIDEOES_PAYMENT_BY_PAYPAL_START";
export const VOD_VIDEOES_PAYMENT_BY_PAYPAL_SUCCESS =
  "VOD_VIDEOES_PAYMENT_BY_PAYPAL_SUCCESS";
export const VOD_VIDEOES_PAYMENT_BY_PAYPAL_FAILURE =
  "VOD_VIDEOES_PAYMENT_BY_PAYPAL_FAILURE";

export const VOD_VIDEOES_HOME_INDEX_START = "VOD_VIDEOES_HOME_INDEX_START";
export const VOD_VIDEOES_HOME_INDEX_SUCCESS = "VOD_VIDEOES_HOME_INDEX_SUCCESS";
export const VOD_VIDEOES_HOME_INDEX_FAILURE = "VOD_VIDEOES_HOME_INDEX_FAILURE";

export const VOD_VIDEOES_SEARCH_START = "VOD_VIDEOES_SEARCH_START";
export const VOD_VIDEOES_SEARCH_SUCCESS = "VOD_VIDEOES_SEARCH_SUCCESS";
export const VOD_VIDEOES_SEARCH_FAILURE = "VOD_VIDEOES_SEARCH_FAILURE";

export const FETCH_SINGLE_VOD_VIDEOES_FOR_OTHERS_START =
  "FETCH_SINGLE_VOD_VIDEOES_FOR_OTHERS_START";
export const FETCH_SINGLE_VOD_VIDEOES_FOR_OTHERS_SUCCESS =
  "FETCH_SINGLE_VOD_VIDEOES_FOR_OTHERS_SUCCESS";
export const FETCH_SINGLE_VOD_VIDEOES_FOR_OTHERS_FAILURE =
  "FETCH_SINGLE_VOD_VIDEOES_FOR_OTHERS_FAILURE";

export const FETCH_VOD_VIDEOES_TRANSACTION_FOR_USER_START =
  "FETCH_VOD_VIDEOES_TRANSACTION_FOR_USER_START";
export const FETCH_VOD_VIDEOES_TRANSACTION_FOR_USER_SUCCESS =
  "FETCH_VOD_VIDEOES_TRANSACTION_FOR_USER_SUCCESS";
export const FETCH_VOD_VIDEOES_TRANSACTION_FOR_USER_FAILURE =
  "FETCH_VOD_VIDEOES_TRANSACTION_FOR_USER_FAILURE";

export const FETCH_VOD_VIDEOES_TRANSACTION_FOR_OWNER_START =
  "FETCH_VOD_VIDEOES_TRANSACTION_FOR_OWNER_START";
export const FETCH_VOD_VIDEOES_TRANSACTION_FOR_OWNER_SUCCESS =
  "FETCH_VOD_VIDEOES_TRANSACTION_FOR_OWNER_SUCCESS";
export const FETCH_VOD_VIDEOES_TRANSACTION_FOR_OWNER_FAILURE =
  "FETCH_VOD_VIDEOES_TRANSACTION_FOR_OWNER_FAILURE";

export const FETCH_SINGLE_VOD_VIDEOES_TRANSACTION_START =
  "FETCH_SINGLE_VOD_VIDEOES_TRANSACTION_START";
export const FETCH_SINGLE_VOD_VIDEOES_TRANSACTION_SUCCESS =
  "FETCH_SINGLE_VOD_VIDEOES_TRANSACTION_SUCCESS";
export const FETCH_SINGLE_VOD_VIDEOES_TRANSACTION_FAILURE =
  "FETCH_SINGLE_VOD_VIDEOES_TRANSACTION_FAILURE";

export const FETCH_PROMO_CODE_START = "FETCH_PROMO_CODE_START";
export const FETCH_PROMO_CODE_SUCCESS = "FETCH_PROMO_CODE_SUCCESS";
export const FETCH_PROMO_CODE_FAILURE = "FETCH_PROMO_CODE_FAILURE";

export const PROMO_CODE_SAVE_START = "PROMO_CODE_SAVE_START";
export const PROMO_CODE_SAVE_SUCCESS = "PROMO_CODE_SAVE_SUCCESS";
export const PROMO_CODE_SAVE_FAILURE = "PROMO_CODE_SAVE_FAILURE";

export const PROMO_CODE_DELETE_START = "PROMO_CODE_DELETE_START";
export const PROMO_CODE_DELETE_SUCCESS = "PROMO_CODE_DELETE_SUCCESS";
export const PROMO_CODE_DELETE_FAILURE = "PROMO_CODE_DELETE_FAILURE";

export const ORDERS_PAYMENT_BY_WALLET_START = "ORDERS_PAYMENT_BY_WALLET_START";
export const ORDERS_PAYMENT_BY_WALLET_SUCCESS = "ORDERS_PAYMENT_BY_WALLET_SUCCESS";
export const ORDERS_PAYMENT_BY_WALLET_FAILURE = "ORDERS_PAYMENT_BY_WALLET_FAILURE";

export const ORDERS_PAYMENT_BY_CARD_START = "ORDERS_PAYMENT_BY_CARD_START";
export const ORDERS_PAYMENT_BY_CARD_SUCCESS = "ORDERS_PAYMENT_BY_CARD_SUCCESS";
export const ORDERS_PAYMENT_BY_CARD_FAILURE = "ORDERS_PAYMENT_BY_CARD_FAILURE";

export const ORDERS_PAYMENT_BY_PAYPAL_START = "ORDERS_PAYMENT_BY_PAYPAL_START";
export const ORDERS_PAYMENT_BY_PAYPAL_SUCCESS = "ORDERS_PAYMENT_BY_PAYPAL_SUCCESS";
export const ORDERS_PAYMENT_BY_PAYPAL_FAILURE = "ORDERS_PAYMENT_BY_PAYPAL_FAILURE";

export const FETCH_SINGLE_PRODUCT_ORDERS_START = "FETCH_SINGLE_PRODUCT_ORDERS_START";
export const FETCH_SINGLE_PRODUCT_ORDERS_SUCCESS = "FETCH_SINGLE_PRODUCT_ORDERS_SUCCESS";
export const FETCH_SINGLE_PRODUCT_ORDERS_FAILURE = "FETCH_SINGLE_PRODUCT_ORDERS_FAILURE";

export const TWO_STEP_AUTH_UPDATE_START = "TWO_STEP_AUTH_UPDATE_START"
export const TWO_STEP_AUTH_UPDATE_SUCCESS = "TWO_STEP_AUTH_UPDATE_SUCCESS"
export const TWO_STEP_AUTH_UPDATE_FAILURE = "TWO_STEP_AUTH_UPDATE_FAILURE"

export const TWO_STEP_AUTHENTICATION_LOGIN_START = "TWO_STEP_AUTHENTICATION_LOGIN_START"
export const TWO_STEP_AUTHENTICATION_LOGIN_SUCCESS = "TWO_STEP_AUTHENTICATION_LOGIN_SUCCESS"
export const TWO_STEP_AUTHENTICATION_LOGIN_FAILURE = "TWO_STEP_AUTHENTICATION_LOGIN_FAILURE"

export const TWO_STEP_AUTHENTICATION_CODE_RESEND_START = "TWO_STEP_AUTHENTICATION_CODE_RESEND_START"
export const TWO_STEP_AUTHENTICATION_CODE_RESEND_SUCCESS = "TWO_STEP_AUTHENTICATION_CODE_RESEND_SUCCESS"
export const TWO_STEP_AUTHENTICATION_CODE_RESEND_FAILURE = "TWO_STEP_AUTHENTICATION_CODE_RESEND_FAILURE"

export const FETCH_OTHER_MODEL_PRODUCT_LIST_START = "FETCH_OTHER_MODEL_PRODUCT_LIST_START";
export const FETCH_OTHER_MODEL_PRODUCT_LIST_SUCCESS = "FETCH_OTHER_MODEL_PRODUCT_LIST_SUCCESS";
export const FETCH_OTHER_MODEL_PRODUCT_LIST_FAILURE = "FETCH_OTHER_MODEL_PRODUCT_LIST_FAILURE";

export const SAVE_FEATURE_STORY_START = "SAVE_FEATURE_STORY_START";
export const SAVE_FEATURE_STORY_SUCCESS = "SAVE_FEATURE_STORY_SUCCESS";
export const SAVE_FEATURE_STORY_FAILURE = "SAVE_FEATURE_STORY_FAILURE";

export const DELETE_FEATURE_STORY_START = "DELETE_FEATURE_STORY_START";
export const DELETE_FEATURE_STORY_SUCCESS = "DELETE_FEATURE_STORY_SUCCESS";
export const DELETE_FEATURE_STORY_FAILURE = "DELETE_FEATURE_STORY_FAILURE";

export const LIVE_VIDEOS_PAYMENT_BY_WALLET_START = "LIVE_VIDEOS_PAYMENT_BY_WALLET_START";
export const LIVE_VIDEOS_PAYMENT_BY_WALLET_SUCCESS = "LIVE_VIDEOS_PAYMENT_BY_WALLET_SUCCESS";
export const LIVE_VIDEOS_PAYMENT_BY_WALLET_FAILURE = "LIVE_VIDEOS_PAYMENT_BY_WALLET_FAILURE";

export const FETCH_SESSION_MANAGEMENT_LIST_START = "FETCH_SESSION_MANAGEMENT_LIST_START";
export const FETCH_SESSION_MANAGEMENT_LIST_SUCCESS = "FETCH_SESSION_MANAGEMENT_LIST_SUCCESS";
export const FETCH_SESSION_MANAGEMENT_LIST_FAILURE = "FETCH_SESSION_MANAGEMENT_LIST_FAILURE";

export const DELETE_SINGLE_LOGIN_SESSION_START = "DELETE_SINGLE_LOGIN_SESSION_START";
export const DELETE_SINGLE_LOGIN_SESSION_SUCCESS = "DELETE_SINGLE_LOGIN_SESSION_SUCCESS";
export const DELETE_SINGLE_LOGIN_SESSION_FAILURE = "DELETE_SINGLE_LOGIN_SESSION_FAILURE";

export const DELETE_ALL_LOGIN_SESSION_START = "DELETE_ALL_LOGIN_SESSION_START";
export const DELETE_ALL_LOGIN_SESSION_SUCCESS = "DELETE_ALL_LOGIN_SESSION_SUCCESS";
export const DELETE_ALL_LOGIN_SESSION_FAILURE = "DELETE_ALL_LOGIN_SESSION_FAILURE";

export const FETCH_CATEGORY_LISTING_START = "FETCH_CATEGORY_LISTING_START";
export const FETCH_CATEGORY_LISTING_SUCCESS = "FETCH_CATEGORY_LISTING_SUCCESS";
export const FETCH_CATEGORY_LISTING_FAILURE = "FETCH_CATEGORY_LISTING_FAILURE";

export const FETCH_POPULAR_LIVE_VIDEOS_START = "FETCH_POPULAR_LIVE_VIDEOS_START";
export const FETCH_POPULAR_LIVE_VIDEOS_SUCCESS = "FETCH_POPULAR_LIVE_VIDEOS_SUCCESS";
export const FETCH_POPULAR_LIVE_VIDEOS_FAILURE = "FETCH_POPULAR_LIVE_VIDEOS_FAILURE";
export const FETCH_MORE_POPULAR_LIVE_VIDEOS_START = "FETCH_MORE_POPULAR_LIVE_VIDEOS_START";

export const FETCH_RECOMMENDED_LIVE_VIDEOS_START = "FETCH_RECOMMENDED_LIVE_VIDEOS_START";
export const FETCH_RECOMMENDED_LIVE_VIDEOS_SUCCESS = "FETCH_RECOMMENDED_LIVE_VIDEOS_SUCCESS";
export const FETCH_RECOMMENDED_LIVE_VIDEOS_FAILURE = "FETCH_RECOMMENDED_LIVE_VIDEOS_FAILURE";
export const FETCH_MORE_RECOMMENDED_LIVE_VIDEOS_START = "FETCH_MORE_RECOMMENDED_LIVE_VIDEOS_START";

export const FETCH_LIVE_VIDEOS_LIST_START = "FETCH_LIVE_VIDEOS_LIST_START";
export const FETCH_LIVE_VIDEOS_LIST_SUCCESS = "FETCH_LIVE_VIDEOS_LIST_SUCCESS";
export const FETCH_LIVE_VIDEOS_LIST_FAILUER = "FETCH_LIVE_VIDEOS_LIST_FAILURE";
export const FETCH_MORE_LIVE_VIDEOS_LIST_START = "FETCH_MORE_LIVE_VIDEOS_LIST_START";

export const SINGLE_LIVE_VIDEO_VIEW_START = "SINGLE_LIVE_VIDEO_VIEW_START";
export const SINGLE_LIVE_VIDEO_VIEW_SUCCESS = "SINGLE_LIVE_VIDEO_VIEW_SUCCESS";
export const SINGLE_LIVE_VIDEO_VIEW_FAILURE = "SINGLE_LIVE_VIDEO_VIEW_FAILURE";

export const FETCH_LIVE_AUDIENCE_LIST_START = "FETCH_LIVE_AUDIENCE_LIST_START";
export const FETCH_LIVE_AUDIENCE_LIST_SUCCESS = "FETCH_LIVE_AUDIENCE_LIST_SUCCESS";
export const FETCH_LIVE_AUDIENCE_LIST_FAILURE = "FETCH_LIVE_AUDIENCE_LIST_FAILURE";

export const FETCH_LIVE_VIDEO_CHAT_MESSAGE_LIST_START = "FETCH_LIVE_VIDEO_CHAT_MESSAGE_LIST_START";
export const FETCH_LIVE_VIDEO_CHAT_MESSAGE_LIST_SUCCESS = "FETCH_LIVE_VIDEO_CHAT_MESSAGE_LIST_SUCCESS";
export const FETCH_LIVE_VIDEO_CHAT_MESSAGE_LIST_FAILURE = "FETCH_LIVE_VIDEO_CHAT_MESSAGE_LIST_FAILURE";

export const SAVE_LIVE_CHAT_MESSAGE_START = "SAVE_LIVE_CHAT_MESSAGE_START";
export const SAVE_LIVE_CHAT_MESSAGE_SUCCESS = "SAVE_LIVE_CHAT_MESSAGE_SUCCESS";
export const SAVE_LIVE_CHAT_MESSAGE_FAILURE = "SAVE_LIVE_CHAT_MESSAGE_FAILURE";

export const ADD_LIVE_VIDEO_MESSAGE_CONTENT = "ADD_LIVE_VIDEO_MESSAGE_CONTENT";

export const FETCH_VIDEO_CALL_CHAT_MESSAGE_START = "FETCH_VIDEO_CALL_CHAT_MESSAGE_START"
export const FETCH_VIDEO_CALL_CHAT_MESSAGE_SUCCESS = "FETCH_VIDEO_CALL_CHAT_MESSAGE_SUCCESS"
export const FETCH_VIDEO_CALL_CHAT_MESSAGE_FAILURE = "FETCH_VIDEO_CALL_CHAT_MESSAGE_FAILURE"

export const ADD_VIDEO_CALL_MESSAGE_CONTENT = "ADD_VIDEO_CALL_MESSAGE_CONTENT"

export const FETCH_AUDIO_CALL_CHAT_MESSAGE_START = "FETCH_AUDIO_CALL_CHAT_MESSAGE_START"
export const FETCH_AUDIO_CALL_CHAT_MESSAGE_SUCCESS = "FETCH_AUDIO_CALL_CHAT_MESSAGE_SUCCESS"
export const FETCH_AUDIO_CALL_CHAT_MESSAGE_FAILURE = "FETCH_AUDIO_CALL_CHAT_MESSAGE_FAILURE"

export const ADD_AUDIO_CALL_MESSAGE_CONTENT = "ADD_AUDIO_CALL_MESSAGE_CONTENT";
export const UPDATE_LIVE_AUDIENCE_LIST = "UPDATE_LIVE_AUDIENCE_LIST";

export const UPDATE_LIVE_AUDIENCE_COUNT = "UPDATE_LIVE_AUDIENCE_COUNT";

export const UPDATE_LIVE_AUDIENCE_EARNING = "UPDATE_LIVE_AUDIENCE_EARNING";

//nft actions

export const NFT_USER_PROFILE_ITEMS_LIST_START = "NFT_USER_PROFILE_ITEMS_LIST_START";
export const NFT_USER_PROFILE_ITEMS_LIST_SUCCESS = "NFT_USER_PROFILE_ITEMS_LIST_SUCCESS";
export const NFT_USER_PROFILE_ITEMS_LIST_FAILURE = "NFT_USER_PROFILE_ITEMS_LIST_FAILURE";


export const LOAD_MORE_USER_PROFILE_ITEMS_LIST_START = "LOAD_MORE_USER_PROFILE_ITEMS_LIST_START"
export const LOAD_MORE_USER_PROFILE_ITEMS_LIST_SUCCESS = "LOAD_MORE_USER_PROFILE_ITEMS_LIST_SUCCESS"
export const LOAD_MORE_USER_PROFILE_ITEMS_LIST_FAILURE = "LOAD_MORE_USER_PROFILE_ITEMS_LIST_FAILURE"

export const FETCH_NFT_COLLECTIONS_LIST_START = "FETCH_NFT_COLLECTIONS_LIST_START"
export const FETCH_NFT_COLLECTIONS_LIST_SUCCESS = "FETCH_NFT_COLLECTIONS_LIST_SUCCESS"
export const FETCH_NFT_COLLECTIONS_LIST_FAILURE = "FETCH_NFT_COLLECTIONS_LIST_FAILURE"

export const SAVE_NFT_COLLECTIONS_ITEMS_START = "SAVE_NFT_COLLECTIONS_ITEMS_START";
export const SAVE_NFT_COLLECTIONS_ITEMS_SUCCESS = "SAVE_NFT_COLLECTIONS_ITEMS_SUCCESS";
export const SAVE_NFT_COLLECTIONS_ITEMS_FAILURE = "SAVE_NFT_COLLECTIONS_ITEMS_FAILURE";





